import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0];

export const dictionary = {
		"/": [7],
		"/auth": [35,[6]],
		"/auth/goodbye/[lang]": [36,[6]],
		"/auth/login": [~37,[6]],
		"/auth/password": [38,[6]],
		"/auth/password/success": [40,[6]],
		"/auth/password/[token]": [39,[6]],
		"/(app)/board": [8,[2]],
		"/(public)/confirm_email/[token]": [24],
		"/(app)/integrations": [9,[2]],
		"/(app)/integrations/notion": [~10,[2]],
		"/(app)/integrations/webhooks": [11,[2]],
		"/(app)/integrations/zapier": [12,[2]],
		"/logout": [41],
		"/(public)/maintenance": [25],
		"/(app)/notes": [13,[2,3]],
		"/(app)/notes/new": [15,[2,3]],
		"/(app)/notes/[id]": [~14,[2,3]],
		"/(app)/settings": [16,[2]],
		"/(public)/setup": [26,[5]],
		"/(public)/setup/finish": [27,[5]],
		"/(public)/setup/join": [~28,[5]],
		"/(public)/setup/join/guest": [~29,[5]],
		"/(public)/setup/layout": [30,[5]],
		"/(public)/setup/plan": [31,[5]],
		"/(public)/setup/settings": [32,[5]],
		"/(public)/setup/user-info": [~33,[5]],
		"/(public)/setup/welcome": [34,[5]],
		"/(app)/tags": [17,[2,4]],
		"/(app)/tags/[id]": [18,[2,4]],
		"/(app)/templates": [~19,[2]],
		"/(app)/templates/new": [~21,[2]],
		"/(app)/templates/[id]": [~20,[2]],
		"/(app)/v/id/[id]": [23,[2]],
		"/(app)/v/[...folder]": [22,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://d67165099cf94cae8686eab784729265@bugtracker.lunanotes.io/3',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [],
	environment: import.meta.env.VITE_NODE_ENV,
	ignoreErrors: [
		'Access is denied for this document',
		'Extension context invalidated.',
		'ResizeObserver loop limit exceeded',
		'Invalid combination'
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
